import React from 'react';
import './Dash.css';

function Dash() {

    return (
        <>
            <div className='curved-div'>
                <h1 className='dash-title' >QUEEN PINEAPPLE</h1>
                <h1 className='dash-title-two'>FARM</h1>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fill-opacity="1" d="M0,192L30,170.7C60,149,120,107,180,80C240,53,300,43,360,58.7C420,75,480,117,540,154.7C600,192,660,224,720,245.3C780,267,840,277,900,250.7C960,224,1020,160,1080,144C1140,128,1200,160,1260,181.3C1320,203,1380,213,1410,218.7L1440,224L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
            </div>
            <div className='curved-div2'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#077e39" fill-opacity="1" d="M0,192L30,170.7C60,149,120,107,180,80C240,53,300,43,360,58.7C420,75,480,117,540,154.7C600,192,660,224,720,245.3C780,267,840,277,900,250.7C960,224,1020,160,1080,144C1140,128,1200,160,1260,181.3C1320,203,1380,213,1410,218.7L1440,224L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z"></path></svg>
            </div>
        </>

    )
}

export default Dash